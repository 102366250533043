import styled from 'styled-components';
import UserContactOwner from './user-contact-owner.component';

const UserContactOwnerStyled = styled(UserContactOwner)`
  font-family: Inter UI;
  display: flex;
  flex-direction: column;
  height: 100vh;

  width: calc(100vw - 32px);
  padding: 0;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  .contact-owner-container {
    flex: 1;

    .contact-owner-buttons-container {
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;

      .message__button-container {
        width: 100%;
        padding: 13px 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #f54286;
        border-radius: 8px;
        margin: 12px 0px;
        cursor: pointer;

        span {
          text-decoration: none;
          text-align: center;
          color: white;
          padding-left: 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .report__button-container {
        width: 100%;
        padding: 13px 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid #dfdfdf;
        box-sizing: border-box;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        margin: 12px 0px;
        cursor: pointer;

        span {
          text-decoration: none;
          text-align: center;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
          padding-left: 8px;
        }
      }
    }
  }

  .contact-owner__other-means {
    margin-top: 60px;
    margin-bottom: 32px;
    text-align: center;
  }

  .contact-owner__download-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .join-community__text {
      font-weight: normal;
      font-size: 16px;
      color: #787878;
      text-align: center;
    }
  }
`;

export default UserContactOwnerStyled;
