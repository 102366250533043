import styled from 'styled-components';
import SafetagNotLinkedWithApp from './safetag-not-linked-with-app.component';

export default styled(SafetagNotLinkedWithApp)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-width: 768px;
  margin: auto;
  padding: 0px 16px;
  justify-content: space-between;

  .safetag-not-linked {

    &__superior-container {

      font-family: Inter UI, Helvetica, Arial, sans-serif;
      justify-content: space-between;
      flex-direction: column;
      text-align: center;
      display: flex;
      
      @media (min-height: 650px) {
        padding-top: 5vh;
      }

      &__img-container {
        text-align: center;
        margin: 56px 0px;
        img {
          width: 90%;
          max-width 423px:
          height: auto;
        }
      }

      &__text-container {
        h1 {
          font-size: 1.25rem;
          font-weight: 600;
          margin: 0px 0px 16px;
          letter-spacing: calc(1rem * -0.019);
        }

        p {
          font-size: 1rem;
          font-weight: 400;
          letter-spacing: calc(1rem * -0.011);
        }
      }

    }

    &__inferior-container {

      font-family: Inter UI, Helvetica, Arial, sans-serif;
      justify-content: space-between;
      flex-direction: column;
      text-align: center;
      display: flex;
      margin-bottom: 5vh;

      &__button-container {
        width: 100%;
        button {
          width: 100%;
          font-size: 1rem;
          letter-spacing: calc(1rem * -0.011);
          font-weight: 400;
        }
      }
    }

    &__disclaimer {
      &__container {
        display: flex;
        flex-diretion: row;
        align-items: center;
        margin-bottom: 16px;
      }
      &__text {
        color: #F54286;
        font-size: 1rem;
        font-weight: 500;
        text-align: left;
        margin-bottom: 0px;
      }
      &__icon {
        margin-right: 10px;
      }
    }

  }
`;
